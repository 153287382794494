const LOOP_DURATION: number = 5;

export const handleTimeCallback =
  (
    smallBreakpoint: boolean,
    player: any,
    loop: boolean,
    videoStartTime: number,
    portraitClipPlayDuration: number,
    setComplete: ( state: boolean ) => void,
    onComplete: () => void
  ) =>
  ( video: { currentTime: number; duration: number }) => {
    // looping
    if ( smallBreakpoint ) {
      if ( loop && video.currentTime >= Math.min( videoStartTime + LOOP_DURATION, video.duration )) {
        player?.seek( videoStartTime );
      }
    }

    // end
    if (
      Math.floor( video.currentTime * 10 ) >=
      Math.floor(
        ( portraitClipPlayDuration ? videoStartTime + portraitClipPlayDuration : video.duration ) * 10
      )
    ) {
      onComplete();
    } else {
      setComplete( false );
    }
  };
