import React from 'react';
import cls from 'classnames';

import { IEventData } from 'src/shoppe/services/JWPlayer';

import { Replay } from './elements/Replay';
import { Visit } from './elements/Visit';

import styles from './index.module.sass';

interface EndOverlayProps {
  eventData: IEventData;
  productLink: string;
  startTime?: number;
  visible: boolean;
  onClickReplay: () => void;
}

export const EndOverlay = ({
  eventData,
  productLink,
  startTime,
  visible,
  onClickReplay,
}: EndOverlayProps ) => (
  <div
    className={cls(
      styles.container,
      'position-absolute d-flex flex-column justify-content-center align-items-center px-4 font-weight-bold ff-cabin',
      {
        [styles.visible]: visible,
      }
    )}
  >
    <Visit link={productLink} />
    <Replay eventData={eventData} startTime={startTime} onClick={onClickReplay} />
  </div>
);
