import React from 'react';

import styles from './index.module.sass';

interface VisitProps {
  link: string;
}

export const Visit = ({ link }: VisitProps ) => (
  <a className={`${styles.link} btn btn-outline-light py-3 mt-7 md-sm-0`} href={link}>
    Watch + Buy
  </a>
);
