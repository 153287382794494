import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-solid-svg-icons';

import { track, IEventData, IEventSource } from 'src/shoppe/services/JWPlayer';
import { useJWContext } from 'src/shoppe/hooks/useJWContext';

import styles from './index.module.sass';

interface ReplayProps {
  eventData: IEventData;
  startTime?: number;
  onClick: () => void;
}

export const Replay = ({ eventData, startTime, onClick }: ReplayProps ) => {
  const { getPlayer } = useJWContext();

  const buttonRef = useRef<HTMLButtonElement>( null );

  useEffect(() => {
    const button = buttonRef.current;
    const handleClick = ( event: MouseEvent ) => {
      event.preventDefault();
      event.stopPropagation(); // only works in listener vs. React event

      getPlayer()?.seek( startTime );
      getPlayer()?.play();

      onClick();
      track.click( event.target, getPlayer()?.id, IEventSource.REPLAY, eventData );
    };

    if ( button ) {
      button.addEventListener( 'click', handleClick );
    }
    return () => {
      button.removeEventListener( 'click', handleClick );
    };
  }, [ eventData, getPlayer, startTime, onClick ]);

  return (
    <button
      aria-label="Replay"
      className={`${styles.button} padding-0 border-0 mt-3 mt-md-6 bg-transparent text-white fs-5 spec__tv-card-replay`}
      ref={buttonRef}
      type="button"
    >
      Replay
      <FontAwesomeIcon className="ml-2" icon={faUndo} />
    </button>
  );
};
