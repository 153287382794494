import React, { useEffect } from 'react';
import cls from 'classnames';

import { JWCustomElem } from 'src/shoppe/elements/JWCustomElem';
import { JWPlayer } from 'src/shoppe/elements/JWPlayer';
import { IEventData } from 'src/shoppe/services/JWPlayer';
import { LazyImgix } from 'src/explore/elements/LazyImgix';

import { EndOverlay } from './elements/EndOverlay';
import { Mute } from './elements/Mute';

import styles from './index.module.sass';

interface VideoProps {
  complete: boolean;
  eventData: IEventData;
  loaded: boolean;
  looping: boolean;
  mountVideo: boolean;
  playerID: string;
  productLink: string;
  productName: string;
  startTime?: number;
  videoID: string;
  onClickReplay: () => void;
  onError: ( obj: any ) => void;
  onLoad?: () => void;
  onMount?: () => void;
  onReady: () => void;
  onTime: ( event: { currentTime: number; duration: number }) => void;
}

export const Video = ({
  complete,
  eventData,
  loaded,
  looping,
  mountVideo,
  playerID,
  productLink,
  productName,
  startTime,
  videoID,
  onClickReplay,
  onError,
  onLoad,
  onMount,
  onReady,
  onTime,
}: VideoProps ) => {
  useEffect(() => {
    if ( onMount ) {
      onMount();
    }
  }, []);

  return (
    <div
      className={cls( styles.container, 'position-relative', {
        [styles.loaded]: loaded,
      })}
    >
      <LazyImgix
        className={`${styles.image} position-absolute w-100`}
        src={`https://cdn.jwplayer.com/v2/media/${videoID}/poster.jpg`}
        alt={productName}
        imgixParams={{ ar: '4:5' }}
        sizes="(min-width: 768px) 25vw, (min-width: 576px) 33vw, 100vw"
      />

      {mountVideo && (
        <JWPlayer
          aspectRatio="4:5"
          className={cls( styles.player, 'position-absolute w-100' )}
          customProps={{
            controls: false,
            stretching: 'fill',
          }}
          file={`https://cdn.jwplayer.com/manifests/${videoID}.m3u8`}
          isMuted
          playerId={playerID}
          onAutoStartNotAllowed={onError}
          onError={onError}
          onReady={onReady}
          onSetupError={onError}
          onTime={onTime}
          onVideoLoad={() => {
            if ( onLoad ) {
              onLoad();
            }
          }}
        >
          <JWCustomElem id={`${playerID}-overlay-root`}>
            <>
              <EndOverlay
                eventData={eventData}
                productLink={productLink}
                startTime={startTime}
                visible={complete}
                onClickReplay={onClickReplay}
              />
              <Mute activate={looping} eventData={eventData} hide={complete} />
            </>
          </JWCustomElem>
        </JWPlayer>
      )}
    </div>
  );
};
