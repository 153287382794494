import React from 'react';
import cls from 'classnames';

import { SvgUse } from 'src/shoppe/elements/SvgUse';

import styles from './index.module.sass';

export const TVIcon = () => (
  <SvgUse
    ariaLabel="Featured on GBTV"
    className={cls( styles.icon, 'position-absolute text-white' )}
    name="icon-gbtv"
  />
);
