import React, { useMemo } from 'react';

import { LazyImgix } from 'src/explore/elements/LazyImgix';
import { IPromotion } from 'src/explore/types/shoppe';
import { URLHelper } from 'src/explore/services/URLHelper';

interface PromotionCardProps {
  linkParameters?: {
    [key: string]: string;
  };
  promotion: IPromotion;
}

export const PromotionCard = ({ linkParameters, promotion }: PromotionCardProps ) => {
  const productUrl = useMemo(
    () => URLHelper.href( promotion.link, linkParameters ),
    [ linkParameters, promotion.link ]
  );

  return (
    <a className="d-block fs-0" href={productUrl}>
      <LazyImgix
        alt={promotion.alt}
        className="w-100"
        src={promotion.image}
        imgixParams={{ ar: '1:1.25' }}
        sizes="(min-width: 768px) 25vw, (min-width: 576px) 33vw, 100vw"
      />
    </a>
  );
};
