import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ProductSummary } from '@goldbely/explore-component-library';
import { pickBy } from 'lodash';

import { useRoutes } from 'src/explore/hooks/useRoutes';
import BreakpointsService from 'src/explore/services/breakpoints';
import { getSiteHeader } from 'src/explore/services/getSiteHeader';
import { IProduct } from 'src/explore/types/shoppe';
import { getJWPlayer, IEventData, IEventSource, track } from 'src/shoppe/services/JWPlayer';

import { TVIcon } from './elements/TVIcon';
import { Video } from './elements/Video';
import { handleTimeCallback } from './lib';

import styles from './index.module.sass';

interface CardProps {
  className?: string;
  linkParameters?: {
    [key: string]: string;
  };
  product: IProduct;
  onLoad?: () => void;
  onMount?: () => void;
}

export const TVCard = ({ className, linkParameters, product, onLoad, onMount }: CardProps ) => {
  const [ complete, _setComplete ] = useState<boolean>( false );
  const [ hover, _setHover ] = useState<boolean>( false );
  const [ loaded, setLoaded ] = useState<boolean>( false );
  const [ loop, setLoop ] = useState<boolean>( BreakpointsService.appliesTo( 'sm' ));
  const [ mountVideo, _setMountVideo ] = useState<boolean>( false );
  const [ player, _setPlayer ] = useState( null );
  const [ error, _setError ] = useState<any>( null );
  const [ rewatchCount, setRewatchCount ] = useState<number>( 0 );
  const [ source, setSource ] = useState<IEventSource>( null );
  const [ watchCount, _setWatchCount ] = useState<number>( 0 );

  const completeRef = useRef( complete );
  const setComplete = ( state: boolean ) => {
    completeRef.current = state;
    _setComplete( state );
  };
  const errorRef = useRef( error );
  const setError = ( e: any ) => {
    errorRef.current = e;
    _setError( e );
  };
  const hoverRef = useRef( hover );
  const setHover = ( state: boolean ) => {
    hoverRef.current = state;
    _setHover( state );
  };
  const mountVideoRef = useRef( mountVideo );
  const setMountVideo = ( state: boolean ) => {
    mountVideoRef.current = state;
    _setMountVideo( state );
  };
  const playerRef = useRef( player );
  const setPlayer = ( state: any ) => {
    playerRef.current = state;
    _setPlayer( state );
  };
  const watchCountRef = useRef( watchCount );
  const setWatchCount = ( state: number ) => {
    watchCountRef.current = state;
    _setWatchCount( state );
  };

  const {
    permalink,
    tvVideo: { clipMediaId, portraitMediaId },
  } = product;
  const playerID = `tv-card-${permalink}`;

  let videoStartTime = 0;
  let videoDuration: number;
  if ( !clipMediaId ) {
    videoStartTime = product.tvVideo.portraitClipStartTime || 0;
    videoDuration = product.tvVideo.portraitClipPlayDuration;
  }

  const handleReady = () => {
    const plyr = getJWPlayer( playerID );
    plyr?.seek( videoStartTime );
    setPlayer( plyr );
  };

  const handleVideoLoad = () => {
    setLoaded( true );

    if ( onLoad ) {
      onLoad();
    }
  };

  const eventData: IEventData = useMemo(
    () => ({
      ...linkParameters,
      merchantId: Number( product.merchant.id ),
      numRewatched: rewatchCount,
      productId: Number( product.id ),
      type: clipMediaId ? 'clip' : 'full video',
      videoId: Number( product.tvVideo.id ),
    }),
    [ clipMediaId, linkParameters, product, rewatchCount ]
  );

  const handleClick = ( event: React.MouseEvent | React.KeyboardEvent<HTMLAnchorElement> ) => {
    track.click( event.target, playerID, IEventSource.SEARCH_CARD, eventData );
  };

  const handleError = ( e: any ) => {
    setError( e );
  };

  const handleKeyDown = ( event: React.KeyboardEvent<HTMLAnchorElement> ) => {
    if ( event.key === 'Enter' || event.key === ' ' ) {
      handleClick( event );
    }
  };

  const handleComplete = () => {
    if ( !complete ) {
      setComplete( true );
      setWatchCount( watchCount + 1 );

      track.watched(
        player?.id,
        Math.round(( player?.getPosition() / player?.getDuration() || 0 ) * 100 ),
        source,
        eventData
      );
      track.stopped( playerID, source, eventData );
    }
  };

  const handleTime = handleTimeCallback(
    BreakpointsService.appliesTo( 'sm' ),
    player,
    loop,
    videoStartTime,
    videoDuration,
    setComplete,
    handleComplete
  );

  const handleMouseEnter = () => {
    setHover( true );
  };

  const handleMouseLeave = () => {
    setHover( false );
  };

  const elementRef = useRef( null );

  // play state
  useEffect(() => {
    const handlePlayState = ( setDesktopPlayState?: boolean ) => {
      if ( !errorRef.current ) {
        const element = elementRef.current;

        if ( !completeRef.current ) {
          if ( element ) {
            const scrollTop = window.scrollY;
            const scrollBottom = scrollTop + window.innerHeight;

            const cardTop = window.pageYOffset + element.getBoundingClientRect().top;

            if (
              !mountVideoRef.current &&
              cardTop + element.offsetHeight >= scrollTop - element.offsetHeight &&
              cardTop <= scrollBottom + element.offsetHeight
            ) {
              setMountVideo( true );
            }

            if (
              cardTop + element.offsetWidth >= scrollTop + getSiteHeader.height() &&
              cardTop + element.offsetWidth * 0.25 <= scrollBottom
            ) {
              if ( playerRef.current && playerRef.current.getState() !== 'playing' ) {
                playerRef.current.play();

                setRewatchCount( watchCountRef.current );
                setSource( IEventSource.AUTOPLAY );

                track.played( playerID, IEventSource.AUTOPLAY, eventData );
              }
            } else {
              playerRef.current?.pause();
            }
          }

          if ( setDesktopPlayState ) {
            if ( hoverRef.current ) {
              setRewatchCount( watchCountRef.current );
              setSource( IEventSource.INTERACTION );

              track.played( playerID, IEventSource.INTERACTION, eventData );
            }

            setLoop( !hoverRef.current );
          }
        }
      }
    };

    handlePlayState( true );
    window.addEventListener( 'resize', () => handlePlayState( true ));
    window.addEventListener( 'scroll', () => handlePlayState());

    return () => {
      window.removeEventListener( 'resize', () => handlePlayState( true ));
      window.removeEventListener( 'scroll', () => handlePlayState());
    };
  }, [ eventData, hover, playerID ]);

  useEffect(() => {
    const handleUnmount = () => {
      if ( !loop && player?.getState() === 'playing' ) {
        track.stopped( playerID, source, eventData );
      }
    };

    window.addEventListener( 'beforeunload', handleUnmount );
    return () => {
      window.removeEventListener( 'beforeunload', handleUnmount );
    };
  }, [ eventData, loop, player, playerID, source ]);

  useEffect(() => {
    if ( error ) {
      window.ahoy.track(
        '$videoError',
        Object.assign( error, { video_id: Number( product.tvVideo.id ) || null })
      );
    }
  }, [ error, product.tvVideo.id ]);

  const routes = useRoutes();
  const link = useMemo(
    () =>
      routes.product(
        product.merchant.permalink.replace( '/restaurants', '' ).replace( '/', '' ),
        product.permalink,
        pickBy( linkParameters )
      ),
    [ linkParameters, product, routes ]
  );

  return error ? null : (
    <li className={className}>
      <div
        className={`${styles.container} position-relative d-block text-dark text-decoration-none fs-3 fs-lg-4 spec__tv-card`}
        ref={elementRef}
        role="button"
        tabIndex={0}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <a
          className={`${styles.link} position-absolute`}
          href={link}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          {( clipMediaId || portraitMediaId ) && (
            <Video
              complete={complete}
              eventData={eventData}
              loaded={loaded}
              looping={loop}
              mountVideo={mountVideo}
              playerID={playerID}
              productLink={link}
              productName={product.effectiveName}
              startTime={videoStartTime}
              videoID={clipMediaId || portraitMediaId}
              onClickReplay={() => {
                setRewatchCount( watchCount );
                setSource( IEventSource.INTERACTION );

                track.played( playerID, IEventSource.INTERACTION, {
                  ...eventData,
                  numRewatched: watchCount,
                });
              }}
              onError={handleError}
              onLoad={handleVideoLoad}
              onMount={onMount}
              onReady={handleReady}
              onTime={handleTime}
            />
          )}
        </a>

        <TVIcon />

        <div className="pt-4 fs-3 fs-lg-4">
          <ProductSummary
            linkParameters={linkParameters}
            product={product as any} // TODO: cleanup IProduct
            showLocation
          />
        </div>
      </div>
    </li>
  );
};
