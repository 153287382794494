import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolume, faVolumeMute } from '@fortawesome/pro-regular-svg-icons';

import { track, IEventData, IEventSource } from 'src/shoppe/services/JWPlayer';
import { useJWContext } from 'src/shoppe/hooks/useJWContext';

import styles from './index.module.sass';

interface MuteProps {
  activate: boolean;
  eventData: IEventData;
  hide: boolean;
}

export const Mute = ({ activate, eventData, hide }: MuteProps ) => {
  const { getPlayer } = useJWContext();
  const [ active, setActive ] = useState( getPlayer()?.getMute());
  const [ choice, setChoice ] = useState( true );

  // sync local mute state with setting on player
  useEffect(() => {
    const updateMute = ( e: { mute: boolean }) => {
      setActive( e.mute );
    };
    getPlayer()?.on( 'mute', updateMute );

    return () => getPlayer()?.off( 'mute', updateMute );
  }, [ getPlayer ]);

  // override user's mute preference appropriately (inactivity)
  useEffect(() => {
    if ( activate ) {
      getPlayer()?.setMute( true );
    } else {
      getPlayer()?.setMute( choice );
    }
  }, [ activate, choice, getPlayer ]);

  const buttonRef = useRef<HTMLButtonElement>( null );

  useEffect(() => {
    const button = buttonRef.current;
    const handleClick = ( event: MouseEvent ) => {
      event.preventDefault();
      event.stopPropagation(); // only works in listener vs. React event

      setChoice( !active );
      getPlayer()?.setMute( !active );

      track.click( event.target, getPlayer()?.id, IEventSource.MUTE, eventData );
    };

    if ( button ) {
      button.addEventListener( 'click', handleClick );
    }
    return () => {
      button.removeEventListener( 'click', handleClick );
    };
  }, [ active, buttonRef, eventData, getPlayer ]);

  return (
    <button
      className={cls(
        styles.button,
        'position-absolute d-none p-2 p-lg-0 border-0 bg-transparent spec__tv-card-mute',
        {
          [styles.hidden]: hide,
        }
      )}
      ref={buttonRef}
      type="button"
    >
      <FontAwesomeIcon
        className="text-white fs-8 fs-lg-10"
        icon={active ? faVolumeMute : faVolume}
      />
    </button>
  );
};
