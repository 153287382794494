import React, { ReactNode } from 'react';
import cls from 'classnames';

import { IProduct, IPromotion } from 'src/explore/types/shoppe';
import { useShopConfig } from 'src/explore/hooks/useShopConfig';

import { CardFactory } from './services/CardFactory';
import { PromotionCard } from './elements/PromotionCard';
import { TVCard } from './elements/TVCard';

import styles from './index.module.sass';

const factory = new CardFactory();

interface ProductGridProps {
  across?: number;
  children?: ReactNode;
  clickRef?: string;
  dumpVideos?: boolean;
  hideLove?: boolean;
  hideMerchant?: boolean;
  noWrap?: boolean;
  products?: Array<IProduct | IPromotion>;
  recommId?: string;
  searchId?: string;
  size?: 'small';
  template: string;
  withTvCards?: boolean;
  onBellowProductContentRender?: ( product: IProduct ) => ReactNode;
  onLoadVideo?: () => void;
  onMountVideo?: () => void;
  onProductClassName?: ( product: IProduct ) => string;
}

const ProductGrid = ({
  across = 4,
  children,
  clickRef,
  dumpVideos,
  hideLove,
  hideMerchant,
  noWrap,
  products,
  recommId,
  searchId,
  size,
  template,
  withTvCards,
  onBellowProductContentRender,
  onLoadVideo,
  onMountVideo,
  onProductClassName,
}: ProductGridProps ) => {
  const config = useShopConfig();

  if ( products?.length > 0 ) {
    const ProductCard = factory.create( template );

    return (
      <ul
        className={cls( 'd-flex mt-1 spec__collection-products', {
          'flex-wrap': !noWrap,
        })}
      >
        {products.map(( item ) => {
          if ( 'image' in item ) {
            return (
              <li
                className={cls(
                  styles.item,
                  'flex-shrink-0 pt-1 px-1 pb-9 px-md-2',
                  `spec__spotlight-${item.link.split( '/' ).pop()}`,
                  {
                    [styles[`item--${across}-across`]]: !!across,
                  }
                )}
                key={item.position}
              >
                <PromotionCard
                  linkParameters={{
                    ref: clickRef,
                    recomm_id: recommId,
                    search_id: searchId,
                  }}
                  promotion={item}
                />
              </li>
            );
          }

          if (
            withTvCards &&
            item.showGbVideo &&
            item.tvVideo &&
            ( item.tvVideo.clipMediaId || item.tvVideo.portraitMediaId )
          ) {
            return dumpVideos ? null : (
              <TVCard
                className={cls( styles.item, 'flex-shrink-0 pt-1 px-1 pb-9 px-md-2', {
                  [styles[`item--${across}-across`]]: !!across,
                })}
                key={`${item.id}-tv`}
                linkParameters={{
                  ref: clickRef,
                  recomm_id: recommId,
                  search_id: searchId,
                }}
                product={item}
                onLoad={onLoadVideo}
                onMount={onMountVideo}
              />
            );
          }

          return (
            <ProductCard
              across={across}
              hideLove={hideLove || config.isWhitelabel}
              hideMerchant={hideMerchant}
              isWhitelabel={config.isWhitelabel}
              key={item.id}
              linkParameters={{
                ref: clickRef,
                recomm_id: recommId,
                search_id: searchId,
              }}
              product={item}
              recommId={recommId}
              size={size}
              onBellowContentRender={onBellowProductContentRender}
              onClassName={onProductClassName}
            />
          );
        })}
      </ul>
    );
  }

  return children || null;
};

export default ProductGrid;
